import React from 'react';

export const WithIcon = ({ color }: { color: string }) => (
  <svg width="76" height="27" viewBox="0 0 76 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.8144 8.64L20.0944 18.398L15.5044 8.708H13.8044L9.45236 18.296L6.69836 8.64H0.0683594L6.05236 27H11.1524L14.7224 18.806L18.3264 27H23.4264L29.2744 8.64H22.8144Z"
      fill={color}
    />
    <path
      d="M31.4907 6.362H37.7467V0.989998H31.4907V6.362ZM31.4567 27H37.7807V8.64H31.4567V27Z"
      fill={color}
    />
    <path
      d="M51.7117 21.492C50.3857 21.492 49.5697 20.676 49.5697 19.384V13.536H54.0237V8.64H49.4337V3.302H48.1077L39.8797 12.04V13.536H43.2457V20.438C43.2457 24.518 45.7277 27 49.7737 27H54.1257V21.492H51.7117Z"
      fill={color}
    />
    <path
      d="M68.3095 8.13C66.4735 8.13 64.7735 8.776 63.4135 9.864V2.01H57.0895V27H63.4135V17.208C63.4135 14.862 64.6035 13.502 66.5075 13.502C68.1735 13.502 68.9215 14.59 68.9215 16.698V27H75.2455V15.95C75.2455 11.19 72.5256 8.13 68.3095 8.13Z"
      fill={color}
    />
  </svg>
);
