import { WithIcon } from 'components/Icons/WithIcon';
import { Colors } from 'constants/Colors';
import { SALON_CATEGORY_TAG_ASSETS_MAP, TagType } from 'constants/Constants';
import React, { useCallback, useMemo, useState } from 'react';
import { Flex, Image, Text } from 'rebass';
import { Meetup } from 'types/interface';
import { StyleObject } from 'types/styles';
import { isPast } from 'utils/TextUtils';
import {
  getResizedMeetupThumbnailImage,
  isMeetupBeforeOpening,
  isMeetupImminentToSoldOut,
  isMeetupNew,
  isMeetupSoldOut,
  isMeetupTrending,
  meetupImminentText,
} from 'utils/MeetupUtil';
import { useIsMobile } from 'hooks/useIsMobile';

interface SalonItemImageProps {
  meetup: Meetup;
  forSale: boolean;
  visiting: boolean;
  trending?: boolean;
  recentlyAttendedTime?: string;
}

export const SalonItemImage = ({
  meetup,
  forSale,
  visiting,
  trending,
  recentlyAttendedTime,
}: SalonItemImageProps) => {
  const isMobile = useIsMobile();
  const imageSrc = useMemo(() => getResizedMeetupThumbnailImage(meetup, [80, 110], isMobile), [
    isMobile,
    meetup,
  ]);

  const soldOutSubTag = useMemo(() => {
    if (isPast(meetup.sessions[0].date)) {
      return '팔로우 가능';
    }
    return `대기자 ${meetup.waitlistWishCount}명`;
  }, [meetup.sessions, meetup.waitlistWishCount]);

  const isSoldOut = useMemo(() => isMeetupSoldOut(meetup), [meetup]);
  const isBeforeOpening = useMemo(() => isMeetupBeforeOpening(meetup), [meetup]);
  const isImminent = useMemo(() => isMeetupImminentToSoldOut(meetup), [meetup]);

  const isNew = useMemo(() => isMeetupNew(meetup), [meetup]);
  const isTrending = useMemo(() => isMeetupTrending(meetup), [meetup]);
  const meetupCategoryTag = useMemo(() => meetup.tags[TagType.SALON_CATEGORY][0], [meetup.tags]);

  const tagAssets = useMemo(() => SALON_CATEGORY_TAG_ASSETS_MAP[meetupCategoryTag], [
    meetupCategoryTag,
  ]);

  const hasMemberLed = useMemo(
    () => meetup.additionalInformation && meetup.additionalInformation.memberLed,
    [meetup.additionalInformation],
  );

  return (
    <Flex sx={{ ...styles.wrapper, bg: tagAssets.color.bg || '' }}>
      {hasMemberLed ? (
        <>
          <Image src={tagAssets.bgImage} sx={styles.bgImage} />
          <Image
            id="mainImage"
            src={imageSrc}
            sx={{ ...styles.memberLedImage, border: `1px solid ${tagAssets.color.regular}` }}
          />
        </>
      ) : (
        <>
          <Flex sx={styles.hostImageWrapper}>
            <Image
              id="mainImage"
              src={imageSrc}
              sx={{ ...styles.hostImage, border: `1px solid ${tagAssets.color.regular}` }}
            />
            <Flex sx={styles.withImage}>
              <WithIcon color={tagAssets.color.regular} />
            </Flex>
          </Flex>
          <Flex sx={{ ...styles.hostInfo, bg: tagAssets.color.regular }}>
            <Text sx={{ minWidth: 'fit-content' }}>
              <b>{meetup.host && meetup.host.nickname}</b>
            </Text>
            <Flex sx={styles.divider} />
            <Text>{meetup.host && meetup.host.title}</Text>
          </Flex>
        </>
      )}
      {!forSale && visiting && (
        <Flex sx={{ flexDirection: 'row' }}>
          <Flex sx={styles.imminentWrapper}>
            <Flex
              sx={{
                ...styles.waitingTag,
                bg: tagAssets.color.dim,
              }}>
              놀러가기
            </Flex>
          </Flex>
        </Flex>
      )}
      {forSale && !recentlyAttendedTime && (
        <Flex sx={{ flexDirection: 'row' }}>
          {isSoldOut && (
            <Flex sx={styles.soldOutWrapper}>
              <Flex sx={styles.soldOutTag}>
                {isPast(meetup.sessions[0].date) ? '모집 마감' : '대기 가능'}
              </Flex>
              <Flex
                sx={{
                  ...styles.waitingTag,
                  bg: tagAssets.color.dim,
                }}>
                {soldOutSubTag}
              </Flex>
            </Flex>
          )}
          {forSale && !isSoldOut && isImminent && (
            <Flex sx={styles.imminentWrapper}>
              <Flex sx={styles.imminentTag}>{meetupImminentText(meetup)}</Flex>
            </Flex>
          )}
          {forSale && !isSoldOut && !isImminent && isTrending && trending && (
            <Flex sx={{ flexDirection: 'row' }}>
              <Flex sx={styles.trendingWrapper}>
                <Flex sx={styles.trendingTag}>
                  <Image src="/trending_up_white.svg" sx={styles.trendingIcon} />
                  NOW
                </Flex>
              </Flex>
            </Flex>
          )}
          {forSale && isBeforeOpening && (
            <Flex sx={styles.isBeforeOpeningWrapper}>
              <Flex sx={styles.soldOutTag}>오픈 예정</Flex>
              <Flex
                sx={{
                  ...styles.waitingTag,
                  bg: tagAssets.color.dim,
                }}>
                {meetup.openNotificationWishCount > 0
                  ? `대기자 ${meetup.openNotificationWishCount}명`
                  : '대기 가능'}
              </Flex>
            </Flex>
          )}
          {forSale && !isBeforeOpening && !isSoldOut && !isImminent && isNew && !trending && (
            <Flex sx={styles.imminentWrapper}>
              <Flex sx={styles.imminentTag}>NEW</Flex>
            </Flex>
          )}
        </Flex>
      )}
      {forSale && !visiting && recentlyAttendedTime && (
        <Flex sx={{ flexDirection: 'row' }}>
          <Flex sx={styles.imminentWrapper}>
            <Flex sx={styles.recentlyTag}>
              <Image src="/time_icon.svg" sx={styles.timeIcon} />
              {recentlyAttendedTime}
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
const styles: StyleObject = {
  bgImage: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  divider: {
    bg: 'white',
    height: ['10px', '12px'],
    mx: ['5px', '6px'],
    opacity: 0.2,
    width: '1px',
  },
  hostImage: {
    borderRadius: ['39.75px', '55px'],
    height: ['79.5px', '110px'],
    objectFit: 'cover',
    transition: 'all 0.3s ease 0s',
    width: ['79.5px', '110px'],
  },
  hostImageWrapper: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  hostInfo: {
    alignItems: 'center',
    color: 'white',
    fontSize: ['9px', '12px'],
    height: ['23px', '32px'],
    justifyContent: 'center',
    px: '6px',
    width: '100%',
  },
  imminentTag: {
    bg: Colors.imminentRed,
    borderRadius: '4px',
    color: 'white',
    fontSize: ['10px', '12px'],
    fontWeight: 400,
    mb: 'auto',
    px: '6px',
    py: ['4px', '6px'],
  },
  imminentWrapper: {
    bottom: 0,
    left: 0,
    p: ['10px', '15px'],
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10,
  },
  isBeforeOpeningWrapper: {
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%)',
    borderRadius: '4px 4px 0px 0px',
    bottom: 0,
    left: 0,
    p: ['10px', '15px'],
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10,
  },
  memberLedImage: {
    height: ['79.5px', '110px'],
    transition: 'all 0.3s ease 0s',
    width: ['79.5px', '110px'],
    zIndex: 1,
  },
  recentlyTag: {
    alignItems: 'center',
    bg: Colors.nfygNeutralNavy,
    borderRadius: '4px',
    color: 'white',
    fontSize: ['10px', '12px'],
    fontWeight: 400,
    mb: 'auto',
    px: '6px',
    py: ['4px', '6px'],
  },
  soldOutTag: {
    bg: Colors.gray11,
    borderRadius: '4px',
    color: 'white',
    fontSize: ['10px', '12px'],
    fontWeight: 400,
    mb: 'auto',
    px: '6px',
    py: ['4px', '6px'],
  },
  soldOutWrapper: {
    bg: 'rgba(0, 0, 0, 0.36)',
    borderRadius: '4px 4px 0px 0px',
    bottom: 0,
    left: 0,
    p: ['10px', '15px'],
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10,
  },
  timeIcon: {
    height: '11px',
    mr: '4px',
    width: '11px',
  },
  trendingIcon: {
    height: '10px',
    mr: '2px',
    width: '10px',
  },
  trendingTag: {
    alignItems: 'center',
    bg: Colors.trendingGreen,
    borderRadius: '4px',
    color: 'white',
    fontSize: ['10px', '12px'],
    fontWeight: 400,
    mb: 'auto',
    px: '6px',
    py: ['4px', '6px'],
  },
  trendingWrapper: {
    bottom: 0,
    left: 0,
    p: ['10px', '15px'],
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10,
  },
  waitingTag: {
    alignItems: 'center',
    borderRadius: '4px',
    color: 'white',
    fontSize: ['10px', '12px'],
    fontWeight: 400,
    mb: 'auto',
    ml: '4px',
    px: '6px',
    py: ['4px', '6px'],
  },
  withImage: {
    height: ['18.78px', '26px'],
    left: ['7.3px', '10px'],
    mr: 'auto',
    position: 'absolute',
    top: ['41.88px', '58px'],
    width: ['54.38px', '75.18px'],
    zIndex: 1,
  },
  wrapper: {
    ':hover': {
      '#mainImage': {
        transform: 'scale(1.1)',
      },
    },
    alignItems: 'center',
    border: `1px solid ${Colors.bluishGrayda}`,
    borderBottom: '0px',
    borderRadius: '4px 4px 0px 0px',
    flexDirection: 'column',
    height: ['130px', '180px'],
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
};
