import { LinkWrapper } from 'components/common/LinkWrapper';
import { Colors } from 'constants/Colors';
import React, { useMemo } from 'react';
import { Flex, Text, Image } from 'rebass';
import { Meetup, Session, BookmarkButtonType } from 'types/interface';
import { StyleObject } from 'types/styles';
import { formatBriefKSTDate, getDayOfWeek } from 'utils/TextUtils';
import { BookmarkButton } from 'components/Bookmark/BookmarkButton';
import { SalonItemImage } from './SalonItemImage';

interface SalonItemProps {
  meetup: Meetup;
  bookmarked?: boolean;
  onClick?: () => void;
  href?: string;
  forSale?: boolean;
  visiting?: boolean;
  isAttending?: boolean;
  attendingSession?: Session;
  recentlyAttendedTime?: string;
  trending?: boolean;
}

export const SalonItem = ({
  meetup,
  bookmarked,
  onClick,
  href,
  forSale = true,
  visiting = false,
  trending = false,
  isAttending,
  recentlyAttendedTime,
}: SalonItemProps) => {
  const infoText = useMemo(
    () =>
      `${formatBriefKSTDate(meetup.sessions[0]?.date)} · ${getDayOfWeek(
        meetup.sessions[0]?.date,
      )} · ${meetup.briefLocation}`,
    [meetup.briefLocation, meetup.sessions],
  );

  const itemLink = useMemo(() => href || `/meetups/${meetup.id}`, [href, meetup.id]);

  return (
    <Flex sx={styles.container}>
      <Flex sx={styles.wrapper} onClick={onClick}>
        <LinkWrapper href={itemLink}>
          <SalonItemImage
            meetup={meetup}
            forSale={forSale}
            visiting={visiting}
            trending={trending}
            recentlyAttendedTime={recentlyAttendedTime}
          />
        </LinkWrapper>
        <LinkWrapper sx={styles.infoWrapper} href={itemLink}>
          <Text sx={styles.title}>{meetup.title}</Text>
          {meetup.additionalInformation?.reopeningHost && (
            <Flex alignItems="flex-end" mb="6px">
              <Image src="/encore_badge.svg" sx={styles.encoreIcon} />
              <Text sx={styles.encoreText}>앵콜 모임장</Text>
            </Flex>
          )}
          <Text sx={styles.infoText}>{infoText}</Text>
        </LinkWrapper>
        <BookmarkButton
          meetup={meetup}
          bookmarked={bookmarked}
          ButtonType={BookmarkButtonType.CARD_SECTION}
        />
      </Flex>
    </Flex>
  );
};

const styles: StyleObject = {
  container: {
    ':hover': {
      '#mainImage': {
        transform: 'scale(1.1)',
      },
    },
    flexDirection: 'column',
    height: '100%',
    mb: ['20px', '40px'],
    mx: ['5px', '10px'],
    overflow: 'hidden',
    width: ['calc(50% - 10px)', 'calc(25% - 20px)'],
  },
  encoreIcon: {
    height: ['14px', '16px'],
    mr: '4px',
    width: ['14px', '16px'],
  },
  encoreText: {
    color: Colors.gray66,
    fontSize: ['11px', '12px'],
  },
  infoText: {
    color: Colors.gray66,
    fontSize: ['11px', '12px'],
    lineHeight: ['16px', '17px'],
  },
  infoWrapper: {
    bg: Colors.paleWhite,
    border: `1px solid ${Colors.bluishGrayda}`,
    borderRadius: '0px 0px 4px 4px',
    borderTop: '0px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minHeight: '140px',
    p: '14px',
    width: '100%',
  },
  title: {
    color: Colors.gray11,
    fontSize: ['12px', '14px'],
    fontWeight: 'bold',
    lineHeight: ['20px', '22px'],
    mb: 'auto',
  },
  wrapper: {
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    width: '100%',
  },
};
