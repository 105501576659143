/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { userState } from 'src/recoil/UserState';
import { AuthToken, User } from 'types/interface';
import { BaseApi } from 'utils/Api/BaseApi';
import { setUserId } from '@firebase/analytics';
import { getFirebaseAnalytics } from 'utils/Analytics';
import { useRouter } from 'next/router';
import ChannelService from 'utils/ChannelService';
import ClarityService from 'utils/ClarityService';
import { MixpanelTracker } from 'utils/Mixpanel';

interface PageDefaultProps {
  authToken: AuthToken;
  user: User | null;
}

export function PageContainer<T>(Page) {
  return ({ authToken, user, ...props }: T & PageDefaultProps) => {
    const router = useRouter();
    const setUser = useSetRecoilState(userState);

    useEffect(() => {
      ChannelService.loadScript();
      ClarityService.loadScript();

      if (user?.id) {
        ClarityService.setUserId(user.id);
      }

      // visit/visitId/sessions/sessionId 페이지에서는 채널 서비스를 종료한다.

      if (
        router.asPath.match(/^\/meetups\/(\d+)/) ||
        router.asPath.startsWith('/2023-wrapped') ||
        router.asPath.match(/^\/visit\/(\d+)\/sessions\/(\d+)/) ||
        router.asPath.startsWith('/welcome-interview')
      ) {
        ChannelService.shutdown();
        return;
      }

      let mobileNumber;
      if (user?.phoneNumber && user.phoneNumber.startsWith('010')) {
        mobileNumber = `+82${user.phoneNumber.substring(1)}`;
      }
      ChannelService.boot({
        memberId: user?.id,
        pluginKey: '0d5f15e7-c1db-4638-8df6-953b779667cd',
        profile: {
          email: user?.email,
          mobileNumber,
          name: user?.name,
        },
      });
    }, [router.asPath, user]);

    useEffect(() => {
      BaseApi.setToken(authToken);
      setUser(user);
      if (user && user.id && process.env.NODE_ENV === 'production') {
        setUserId(getFirebaseAnalytics(), user.id);

        MixpanelTracker.identify(user.id);
        MixpanelTracker.setPeople({
          $avatar: user?.profileImageUrl,
          $created: user?.createdAt,
          $email: user?.email,
          $name: user?.name,
          $phone: user?.phoneNumber,
          gender: user?.gender,
        });
      }
    }, [authToken, setUser, user]);

    return <Page authToken={authToken} user={user} {...props} />;
  };
}
