/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Image } from 'rebass';
import { WishType, BookmarkButtonType, Meetup } from 'types/interface';
import { StyleObject } from 'types/styles';
import { useRouter } from 'next/router';
import { WishApi } from 'utils/Api/WishApi';
import { userState } from 'src/recoil/UserState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AfterLoginActionKeys, UserMarketingPreferenceKeys } from 'constants/Constants';
import { isMeetupBeforeOpening } from 'utils/MeetupUtil';
import { AuthApi } from 'utils/Api/AuthApi';
import { confirmAlert, defaultToast, noticeToast } from 'utils/Alert';

interface BookmarkButtonProps {
  bookmarked?: boolean;
  meetup: Meetup;
  ButtonType: BookmarkButtonType;
  openNotificationCount?: number;
  onChangeOpenNotification?: (state: boolean, newCount?: number) => void;
  setBookmarkCountState?: React.Dispatch<React.SetStateAction<number>>;
}

export const BookmarkButton = ({
  bookmarked,
  meetup,
  ButtonType,
  openNotificationCount,
  onChangeOpenNotification,
  setBookmarkCountState,
}: BookmarkButtonProps) => {
  const user = useRecoilValue(userState);
  const setUser = useSetRecoilState(userState);
  const router = useRouter();
  const [bookmarkWishState, setBookmarkWishState] = useState<boolean>(bookmarked ?? false);
  const [bookmarkToOpen, setBookmarkToOpen] = useState<string | null>(null);

  const isBeforeOpening = useMemo(() => isMeetupBeforeOpening(meetup), [meetup]);

  const onClickBookmarkButton = useCallback(
    async (targetMeetupId: string) => {
      if (!user) {
        router.push(
          `/my?redirect=${encodeURIComponent(router.asPath)}&afterLoginAction=${
            AfterLoginActionKeys.BOOKMARK
          }/${targetMeetupId}`,
        );
      } else if (!bookmarkWishState) {
        setBookmarkWishState(true);
        if (setBookmarkCountState) {
          setBookmarkCountState(prev => prev + 1);
        }
        try {
          if (
            !bookmarkToOpen &&
            isBeforeOpening &&
            !user?.marketingPreference?.bookmarkToOpenNotification
          ) {
            if (
              (
                await confirmAlert(
                  '찜한 모임 오픈 알림 수신 설정',
                  `찜한 모임이 오픈 되었을 때 알림톡으로 알려드립니다.<br>수신을 설정하시겠습니까?
                <br><br>*[마이페이지 > 설정]에서 언제든지 변경할 수 있습니다.`,
                  '알림 받기',
                )
              ).isConfirmed
            ) {
              const updateMarketingPreference = {
                marketingPreferenceBookmarkToOpenNotification: true,
              };
              const updateUser = await AuthApi.updateUser(user.id, updateMarketingPreference);
              setUser(updateUser.user);
            } else {
              const updateMarketingPreference = {
                marketingPreferenceBookmarkToOpenNotification: false,
              };
              const updateUser = await AuthApi.updateUser(user.id, updateMarketingPreference);
              setUser(updateUser.user);
            }
            localStorage?.setItem(UserMarketingPreferenceKeys.BOOKMARK_TO_OPEN, 'true');
          }
          const res = await WishApi.createWish({
            meetupId: String(targetMeetupId),
            type: WishType.TYPE_BOOKMARK,
          });
          res.syncedWishes?.map(syncedWish => {
            if (
              syncedWish.type === WishType.TYPE_OPEN_NOTIFICATION &&
              String(syncedWish.meetupId) === String(meetup.id) &&
              onChangeOpenNotification
            ) {
              onChangeOpenNotification(true, openNotificationCount + 1);
              defaultToast('해당 모임이 오픈되면 알림톡으로 알려드릴게요!');
            }
          });
        } catch (e) {
          if (e.response?.status === 409) {
            setBookmarkWishState(true);
          } else {
            noticeToast('찜 리스트에 담기 실패! 넷플연가에 문의해주세요.');
            setBookmarkWishState(false);
            if (setBookmarkCountState) {
              setBookmarkCountState(prev => prev - 1);
            }
          }
        }
      } else {
        setBookmarkWishState(false);
        if (setBookmarkCountState) {
          setBookmarkCountState(prev => prev - 1);
        }
        try {
          await WishApi.deleteWish({
            meetupId: String(targetMeetupId),
            type: WishType.TYPE_BOOKMARK,
          });
        } catch (e) {
          if (e.response?.status === 404) {
            setBookmarkWishState(false);
          } else {
            noticeToast('찜 취소하기 실패! 넷플연가에 문의해주세요.');
            setBookmarkWishState(true);
            if (setBookmarkCountState) {
              setBookmarkCountState(prev => prev + 1);
            }
          }
        }
      }
    },
    [
      user,
      bookmarkWishState,
      router,
      bookmarkToOpen,
      isBeforeOpening,
      setUser,
      onChangeOpenNotification,
      openNotificationCount,
    ],
  );

  useEffect(() => {
    const bookmarkToOpenState = localStorage?.getItem(UserMarketingPreferenceKeys.BOOKMARK_TO_OPEN);
    setBookmarkToOpen(bookmarkToOpenState);
  }, [meetup.id, user]);

  return (
    <Image
      sx={
        ButtonType === BookmarkButtonType.BOOKMAKR_PAGE
          ? styles.BookmarkPageheartIcon
          : ButtonType === BookmarkButtonType.CARD_SECTION ||
            ButtonType === BookmarkButtonType.PREOPEN_SECTION
          ? styles.cardHeartIcon
          : styles.ctaHeartIcon
      }
      onClick={() => onClickBookmarkButton(String(meetup.id))}
      src={
        bookmarkWishState
          ? '/heart_pink.svg'
          : ButtonType === BookmarkButtonType.CARD_SECTION
          ? '/heart_white.svg'
          : ButtonType === BookmarkButtonType.CTA_BUTTON
          ? '/heart_black.svg'
          : '/heart_gray.svg'
      }
    />
  );
};

const styles: StyleObject = {
  BookmarkPageheartIcon: {
    cursor: 'pointer',
    height: ['19px', '32px'],
    position: 'absolute',
    right: ['0px', '35px'],
    top: ['3px', '40px'],
    width: ['19px', '32px'],
  },
  cardHeartIcon: {
    cursor: 'pointer',
    height: ['20px', '26px'],
    position: 'absolute',
    right: ['12px', '15px'],
    top: ['12px', '15px'],
    width: ['20px', '26px'],
    zIndex: 15,
  },
  ctaHeartIcon: {
    cursor: 'pointer',
    height: ['15px', '18px'],
    width: ['15px', '18px'],
  },
};
